import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import ErrorBanner from 'sections/ErrorBanner';

const ErrorPage = () => (
  <Layout
  // showHeader={true}
  // showMenu={true}
  // showFooter={true}
  >
    <SEO 
      title="404: Not found" 
      description="Página não encontrada."
    />
    <ErrorBanner errorType={'404'}/>
  </Layout>
);

export default ErrorPage;
