/** @jsx jsx */
import { jsx, Box, Container, Heading, Text} from 'theme-ui';
import { rgba } from 'polished';
import errorImg from 'assets/images/404.jpg';

const ErrorBanner = (props) => {
    var 
        errorType = props.errorType,
        errorHead = '',
        errorMessage='';
    if(errorType === '404'){
        errorHead = "OPSS!!! \nErro 404!";
        errorMessage = "A página que está tentando acessar não existe ou não pode ser acessada agora!"
    }
    return (
        <section id='error' sx={ styles.section}>
            <Container>
                <Box sx={styles.contentWrapper}>
                    <Box sx={styles.bannerContent}>
                        <Heading as='h1' sx={styles.header}>
                            {errorHead}
                        </Heading>
                        <Text as='p' sx={styles.text}>
                            {errorMessage}<br/>
                        </Text>
                    </Box>
                    <Box as='figure' sx={styles.bannerImage}>
                        <img
                            src={errorImg}
                            alt='404'
                        />
                    </Box>
                </Box>
            </Container>
        </section>
    )
}

export default ErrorBanner;

const styles = {
    section: {
        pt: [14, null, null, null, null, 0],
        pb: [6, null, null, 7, 11, 0]
    },
    contentWrapper: {
        mt: 100,
        ml: 200
    },
    bannerContent: {
        mt: 100,
        bg:'transparent'

    },
    header: {
        color: 'primary'
    },
    text: {
        color: 'secondary'
    },
    bannerImage: {
        
        alignItems: 'center',
        mt: [19, null, null, null, 10],
        img: {
            m: [null, null, null, '0 auto', 0],
            width: [200,400,600],
            height: [200,400,600]
        }
    }
};